import styled from 'styled-components';

export const Footer = styled.div`
    width: 100%;
    display: flex;
    padding: 16px 32px;
    border-top: 1px solid ${p => p.theme.borderLight};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    > :not(:last-child) {
        margin-right: 12px;
    }
`;
