import React from 'react';
import styled, { css } from 'styled-components';

import { ErrorMessage } from './ErrorMessage';
import { Label } from './Label';

const Wrapper = styled.div`
    display: block;
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const sharedInputStyles = css`
    width: 100%;
    color: ${p => p.theme.text};
    border-radius: 4px;
    border: none;
    background-color: ${p => p.theme.background};
    font-family: ${p => p.theme.fontFamily};
    font-weight: ${p => p.theme.fontWeight.regular};
    ::placeholder {
        color: ${p => p.theme.textLight};
        opacity: 1;
    }
`;

const StyledTextField = styled.input`
    ${sharedInputStyles};
    height: 40px;
    padding: 8px 12px;
`;

const StyledTextArea = styled.textarea`
    ${sharedInputStyles};
    height: 120px;
    padding: 12px;
`;

const IconWrapper = styled.div`
    padding: 0 8px;
`;

const checkKeyDown = e => {
    if (e.code === 'Enter') e.preventDefault();
};

export const TextField = React.forwardRef((props, ref) => {
    const {
        id,
        required,
        multiline,
        disabled,
        label,
        placeholder,
        onKeyDown = checkKeyDown,
        error,
        icon,
        ...otherProps
    } = props;

    const InputComponent = multiline ? StyledTextArea : StyledTextField;

    return (
        <Wrapper>
            {label ? (
                <Label>{label}{required ? ' (required)' : null}</Label>
            ) : null}
            <InputWrapper>
                <InputComponent
                    {...otherProps}
                    type="text"
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder && `${placeholder}${required ? ' (required)' : ''}`}
                    onKeyDown={onKeyDown}
                    ref={ref}
                />
                {icon ? (
                    <IconWrapper>
                        {icon}
                    </IconWrapper>
                ) : null}
            </InputWrapper>
            {error ? (
                <ErrorMessage>
                    {error.message}
                </ErrorMessage>
            ) : null}
        </Wrapper>
    );
});
