import { useMutation, useQueryClient } from 'react-query';

import { api, ENDPOINTS, useToken } from '~/api';

export const useAddCuratedExamples = () => {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation(payload => api.post(
        ENDPOINTS.CURATED_DESCRIPTORS,
        payload,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            queryClient.invalidateQueries(['curatedDescriptor'])
        },
    });
};