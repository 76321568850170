import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';
import { TextField } from './TextField';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    >:first-child {
        width: 100%;
    }
`;

const CrossIcon = styled(Icon).attrs(p => ({
    type: 'cross',
    color: p.theme.text,
    size: '24px',
}))`
    position: absolute;
    top: 0%;
    right: 12%;
    height: 100%;
    margin-top: 8px;
    
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const SearchInput = ({
    placeholder,
    onValueChanged,
    ...props
}) => {
    const [value, setValue] = React.useState('');

    const handleChange = React.useCallback(
        (event) => {
            setValue(event.target.value);
            onValueChanged(event.target.value);
        },
        [setValue, onValueChanged],
    );

    const handleClear = React.useCallback(
        (event) => {
            setValue('');
            onValueChanged?.('');
        },
        [setValue, onValueChanged],
    );

    return (
        <Wrapper>
            <TextField 
                {...props}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                icon={<CrossIcon onClick={() => handleClear()} />}
            />
        </Wrapper>
     );
}
 