import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import * as refData from '~/core/refData';
import { useFields } from '~/contexts';
import {
    Button,
    Form,
    Icon,
    Label,
    Select,
    TextField,
    ToggleSwitch
} from '~/ui';

const StyledForm = styled(Form)`
    flex: 1;
`;

const StyledField = styled.div`
    display: flex;
    align-items: center;
    max-width: 400px;
    >:first-child {
        margin-right: 8px;
    }
    >:last-child {
        width: 100%;
    }
`;

const StyledChoice = styled.div`
    display: flex;
    align-items: center;
    font-weight: ${p => p.theme.fontWeight.medium};
    color: ${p => p.theme.primary};
    > * {
        :hover {
            cursor: pointer;
        }
    }
`;

const DropdownOption = styled.div`
    display: flex;
    align-items: flex-start;
    max-width: 400px;
`;

const DropdownOptionField = styled.div`
    flex: 1;
    margin-right: 8px;
`;

const StyledButton = styled.div`
    display: flex;
    > * {
        margin: 4px;
    }
`;

const StyledCondition = styled.div`
    display: flex;
    align-items: flex-start;
`;

const ConditionDropdown = styled.div`
    flex: 1;
    max-width: 320px;
    margin-right: 16px;
`;

const ConditionLabel = styled(Label)`
    flex: none;
    margin: 10px 12px 0 0;
`;

const fieldConditionsToFormData = (conditions, fields) => conditions.map(
    condition => {
        const selectedField = fields.find(
            ({ field_id }) => field_id === condition.field_id
        );

        const selectedType = refData.conditionType.values.find(
            ({value}) => (value === condition.type)
        );

        const selectedOption = selectedField.field_type === 'dropdown' ?
            selectedField.field_options.find(
                option => option === condition.value
            ): refData.checkboxValue.values.find(
                ({value}) => (value === condition.value)
            );

        return {
            field: {
                label: selectedField.field_label,
                value: selectedField.field_id,
            },
            type: {
                label:selectedType.label,
                value:selectedType.value,
            },
            value: {
                label: selectedOption?.label ? selectedOption.label : selectedOption,
                value: selectedOption?.value ? selectedOption.value : selectedOption,
            },
        }
    }
);

const ConfigurableField = forwardRef(({
    field: fieldObject,
    onDismiss,
    onDeleteField,
}, ref) => {
    const { fields: fieldsList, dispatch } = useFields();
    const otherFields = fieldsList.filter(field => field._id !== fieldObject._id);
    const otherFieldIds = otherFields.map(({ field_id }) => field_id);

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        watch,
        clearErrors,
    } = useForm({
        shouldUnregister: true,
        defaultValues: {
            field_label: fieldObject.field_label,
            field_id: fieldObject.field_id,
            field_required: fieldObject.field_required,
            field_options: fieldObject.field_options,
            field_conditions: fieldConditionsToFormData(fieldObject.field_conditions, otherFields),
        },
    });

    const {
        fields: conditionFields,
        append: appendCondition,
        remove: removeCondition
    } = useFieldArray({
        control,
        name: "field_conditions",
    });

    const {
        fields: optionFields,
        append: appendOption,
        remove: removeOption
    } = useFieldArray({
        control,
        name: "field_options",
    });

    const getRefDataEqualValue = () => {
        return refData.conditionType.values.find(
            ({value}) => (value === 'equal')
        );
    }

    const onSubmit = (data) => {
        const field_conditions = data.field_conditions.map(
            ({ field, type, value }) => ({
                field_id: field.value,
                type: type.value,
                value: value.value,
            })
        );
        const payload = {
            _id: fieldObject._id,
            field_id: data.field_id,
            field_label: data.field_label,
            field_required: data.field_required,
            field_options: data.field_options,
            field_conditions
        }
        
        dispatch({
            type: 'UPDATE_FIELD',
            payload: payload
        });

        onDismiss();
    };

    const watchOptions = watch('field_options');
    const watchConditions = watch('field_conditions');
    const conditionSelectableFields = otherFields
        .filter(({ field_type }) => field_type === 'dropdown' || field_type === 'checkbox')
        .map(
            ({ field_label, field_id }) => ({ label: field_label, value: field_id })
        );

    const getConditionOptions = selectedFieldId => {
        const selectedField = otherFields.find(
            ({ field_id }) => field_id === selectedFieldId
        );

        if(selectedField.field_type === 'checkbox'){
            return refData.checkboxValue.values;
        }

        return selectedField.field_options.map(
            option => ({ label: option, value: option })
        );
    }

    return (
        <StyledForm ref={ref} onSubmit={handleSubmit(onSubmit)}>
            <StyledField>
                <TextField
                    label="Field label"
                    placeholder="eg. Staff type"
                    error={errors.field_label}
                    {...register('field_label', {
                        required: 'Please enter a field label',
                    })}
                />
            </StyledField>
            <StyledField>
                <TextField
                    label="Unique ID"
                    placeholder="eg. staffType"
                    {...register('field_id', {
                        validate: value => {
                            if (!value) {
                                return 'Please enter a unique ID';
                            }
                            if (!value.match('^[a-zA-Z0-9]+$')) {
                                return 'IDs should be alphanumeric with no spaces';
                            }
                            if (otherFieldIds.includes(value)) {
                                return 'This ID is already being used by another field'
                            }
                            return true;
                        }
                    })}
                    error={errors.field_id}
                />
            </StyledField>
            {fieldObject.field_type !== 'checkbox' ? (
                <ToggleSwitch
                    {...register('field_required')}
                    label="Required"
                    defaultChecked={fieldObject.field_required}
                />
            ): null}
            {fieldObject.field_type === 'dropdown' ? (
                <>
                    <Label>Options</Label>
                    {optionFields.map((option, i) => {
                        return (
                            <DropdownOption key={option.id}>
                                <DropdownOptionField>
                                    <Controller
                                        name={`field_options[${i}]`}
                                        rules={{
                                            validate: value => {
                                                if (!value) {
                                                    return 'Please enter an option label';
                                                }
                                                if (watchOptions.filter(option => option === value).length > 1) {
                                                    return 'Please remove duplicate options';
                                                }
                                                return true;
                                            }
                                        }}
                                        defaultValue={fieldObject?.field_options[i] || ''}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <TextField
                                                placeholder="Enter an option label"
                                                error={errors.field_options?.[i]}
                                                {...field}
                                            />
                                        )}
                                    />
                                </DropdownOptionField>
                                <Button
                                    icon
                                    onClick={() => {
                                        removeOption(i);
                                        clearErrors('field_options');
                                    }}
                                >
                                    <Icon
                                        type="cross"
                                        size="24px"
                                    />
                                </Button>
                            </DropdownOption>
                        );
                    })}
                    <StyledChoice onClick={() => appendOption('')}>
                        <Icon
                            size="24px"
                            color={p => p.theme.primary}
                            type="addItem"
                        />
                        <span>
                            Add option
                        </span>
                    </StyledChoice>
                </>
            ): null}
            {fieldsList.length > 1 ? (
                <>
                    <Label>Display conditions</Label>
                    {conditionFields.map((condition, i) => {
                        const fieldName = `field_conditions[${i}]`;
                        const selectedFieldId = watchConditions[i].field?.value;

                        return (
                            <StyledCondition key={condition.id}>
                                <ConditionLabel>{i === 0 ? 'If' : 'or'}</ConditionLabel>
                                <ConditionDropdown>
                                    <Controller
                                        name={`${fieldName}.field`}
                                        control={control}
                                        rules={{ required: 'Please select a field' }}
                                        defaultValue=""
                                        render={({ field: { ref, ...field } }) => (
                                            <Select
                                                placeholder="Select a field"
                                                options={conditionSelectableFields}
                                                error={errors.field_conditions?.[i].field}
                                                {...field}
                                            />
                                        )}
                                    />
                                </ConditionDropdown>
                                {selectedFieldId ? (
                                    <>
                                        <ConditionDropdown>
                                            <Controller
                                                name={`${fieldName}.type`}
                                                control={control}
                                                rules={{ required: 'Please select a type' }}
                                                defaultValue=""
                                                render={({ field: { ref, ...field } }) => (
                                                    <Select
                                                        placeholder="Select a type"
                                                        options={refData.conditionType.values}
                                                        error={errors.field_conditions?.[i].type}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </ConditionDropdown>
                                        <ConditionDropdown>
                                            <Controller
                                                name={`${fieldName}.value`}
                                                control={control}
                                                rules={{ required: 'Please select a value' }}
                                                defaultValue=""
                                                render={({ field: { ref, ...field }}) => (
                                                    <Select
                                                        placeholder="Select a value"
                                                        options={getConditionOptions(selectedFieldId)}
                                                        error={errors.field_conditions?.[i].value}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </ConditionDropdown>
                                    </>
                                ) : null}
                                <Button
                                    icon
                                    onClick={() => removeCondition(i)}
                                >
                                    <Icon
                                        type="cross"
                                        size="24px"
                                    />
                                </Button>
                            </StyledCondition>
                        )
                    })}
                    <StyledChoice
                        onClick={() => appendCondition({ field: '', type: getRefDataEqualValue(), value: '' })}
                    >
                        <Icon
                            size="24px"
                            color={p => p.theme.primary}
                            type="addItem"
                        />
                        <span>
                            Add condition
                        </span>
                    </StyledChoice>
                </>
            ) : null}
            <StyledButton>
                <Button
                    type="submit"
                >
                    Save
                </Button>
                <Button
                    type="button"
                    secondary
                    onClick={onDeleteField}
                >
                    Delete field
                </Button>
            </StyledButton>
        </StyledForm>
    )
});

export default ConfigurableField;
