import { useMutation, useQueryClient } from 'react-query';
import queryString from 'query-string';

import { api, ENDPOINTS, useToken } from '~/api';

export const useDeleteCustomer = ({ customerId, surveyId }) => {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation(() => api.delete(
        `${ENDPOINTS.CUSTOMERS}/?${queryString.stringify({ customerId })}`,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            queryClient.invalidateQueries(`${ENDPOINTS.CUSTOMERS}/?surveyId=${surveyId}`);
        },
    });
};
