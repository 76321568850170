import React from 'react';
import styled from 'styled-components';

import { useBubbleChart } from './useBubbleChart';
import { DescriptorExamplesModal } from '../DescriptorExamplesModal';

const ChartRoot = styled.div`
    text-align: center;
`;

export const BubbleChart = ({ id, descriptors }) => {
    const { selected: selectedDescriptor, resetSelected } = useBubbleChart({ rootId: id, descriptors });

    return (
        <>
            {selectedDescriptor ? (
                <DescriptorExamplesModal
                    descriptor={selectedDescriptor?.descriptor}
                    onClose={resetSelected}
                />
            ) : null}
            <ChartRoot id={id} />
        </>
    );
};
