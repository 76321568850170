import React from 'react';
import styled from 'styled-components';

const Modal = styled.div`
    z-index: 15;
    position: relative;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 32px);
    width: 600px;
    border-radius: 8px;
    background-color: ${p => p.theme.white};
    color: ${p => p.theme.text};
    box-shadow: 0 0 40px rgba(72, 72, 72, 0.05);
`;

export const Container = ({
    children,
    ...props
}) => (
    <Modal {...props}>
        {children}
    </Modal>
);
