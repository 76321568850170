import styled from 'styled-components';

export const ReportGroupList = styled.ul`
    margin-top: 32px;
    > li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
`;
