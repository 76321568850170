import React from 'react';
import { useUpdateReportGroups } from '~/queries';

import { Button, Modal, Overlay } from '~/ui';

export const DeleteReportGroup = ({
    surveyId,
    reportGroupId,
    reportGroupName,
    onClose,
}) => {
    const updateReportGroups = useUpdateReportGroups({ surveyId, reportGroupId });

    const onSubmit = () => {
        updateReportGroups.mutateAsync().then(onClose);
    };

    return (
        <Overlay dismissOnEscape>
            <Modal.Container>
                <Modal.Header onClose={onClose}>
                    Delete report group
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the <b>{reportGroupName}</b> report group?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onSubmit}>
                        Delete
                    </Button>
                    <Button secondary onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </Overlay>
    );
};
