import { useQuery } from 'react-query';

import { API_URL } from './constants';
import { FetchError } from './FetchError';
import { useToken } from './useToken';

function useQueryGet({
    endpoint,
    queryKey,
    ...options
}) {
    const { token } = useToken();

    return useQuery(
        queryKey || endpoint,
        () => get(endpoint, {
            headers: { Authorization: token },
        }),
        {
            ...options
        },
    );
}

function get(endpoint, options = {}) {
    const url = `${API_URL}${endpoint}`;
    const { headers, ...fetchOptions } = options;

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        ...fetchOptions,
    }).then((response) => {
        if (!response.ok) {
            return response.text().then(message => {
                throw new FetchError(message || `Unknown error querying ${url}`, response);
            });
        }
        return response.text().then(message => {
            return message ? JSON.parse(message) : message;
        });
    });
}

function post(endpoint, payload, options = {}) {
    const url = `${API_URL}${endpoint}`;
    const { headers, ...fetchOptions } = options;

    return fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(payload),
        ...fetchOptions,
    }).then((response) => {
        if (!response.ok) {
            return response.text().then(message => {
                throw new FetchError(message || `Unknown error during POST request to ${url}`, response);
            });
        }
        return response.text().then(message => {
            return message ? JSON.parse(message) : message;
        });
    });
}

function patch(endpoint, payload, options = {}) {
    const url = `${API_URL}${endpoint}`;
    const { headers, ...fetchOptions } = options;

    return fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(payload),
        ...fetchOptions,
    }).then((response) => {
        if (!response.ok) {
            return response.text().then(message => {
                throw new FetchError(message || `Unknown error during PATCH request to ${url}`, response);
            });
        }
        return response.text().then(message => {
            return message ? JSON.parse(message) : message;
        });
    });
}

function deleteRequest(endpoint, options = {}) {
    const url = `${API_URL}${endpoint}`;
    const { headers, ...fetchOptions } = options;

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        ...fetchOptions,
    }).then((response) => {
        if (!response.ok) {
            return response.text().then(message => {
                throw new FetchError(message || `Unknown error during DELETE request to ${url}`, response);
            });
        }
        return response.text();
    });
}

// function patch(endpoint, payload, options = {}) {
//     const url = `${API_URL}${endpoint}`;
//     const { headers, ...fetchOptions } = options;

//     const patch = Object.keys(payload).map((key) => ({
//         op: "add",
//         path: `/${key}`,
//         value: payload[key] ?? null,
//     }));

//     return fetch(url, {
//         method: 'patch',
//         headers: {
//             'Content-Type': 'application/json',
//             ...headers,
//         },
//         body: JSON.stringify(patch),
//         ...fetchOptions,
//     }).then((response) => {
//         if (!response.ok) {
//             return response.text().then(message => {
//                 throw new FetchError(message || `Unknown error during PATCH request to ${url}`, response);
//             });
//         }
//         return response.text().then(message => {
//             return message ? JSON.parse(message) : message;
//         });
//     });
// }

const api = {
    get,
    post,
    patch,
    delete: deleteRequest, // Renamed to avoid conflict with JavaScript keyword
    useQueryGet,
};

export default api;
