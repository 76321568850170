import React from 'react';
import styled from 'styled-components';
import { useTheme } from '~/ui/theme';

import { Icon } from '../Icon';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid ${p => p.theme.borderLight};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

const Title = styled.div`
    font-size: ${p => p.theme.fontSize.xlarge};
    font-weight: ${p => p.theme.fontWeight.medium};
`;

const StyledIcon = styled(Icon)`
    padding: 8px;
    cursor: pointer;
`;

export const Header = ({
    children,
    onClose,
}) => {
    const theme = useTheme();

    return (
        <Container>
            <Title>{children}</Title>
            <StyledIcon
                type="cross"
                size="40px"
                color={theme.text}
                onClick={onClose}
            />
        </Container>
    );
}
