import { api, ENDPOINTS } from '~/api';

export const useBiodataFields = ({ surveyId }) => {
    const { data, ...rest } = api.useQueryGet({
        endpoint: `${ENDPOINTS.SURVEY_FIELDS}/?surveyID=${surveyId}`
    });
    return {
        data: data || [],
        ...rest
    };
};
