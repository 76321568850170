import React from 'react';
import styled, { css } from 'styled-components';
import { escapeRegExp } from 'lodash';
import { useAddCuratedExamples, useUpdateCuratedExamples } from '~/queries';
import { Button, Heading, Icon } from '~/ui';
import { DeleteCuratedExample } from './DeleteCuratedExample';
import { EditCuratedExample } from './EditCuratedExample';

const Wrapper = styled.div`
    min-height: 0;
    flex: 1;
    display: flex;
    border: 1px solid ${p => p.theme.borderLight};
    border-radius: 8px;
    > * {
        flex: 0 0 50%;
    }
`;

const Examples = styled.ul`
    overflow-y: auto;
    border-right: 1px solid ${p => p.theme.borderLight};
`;

const CuratedExamples = styled.ul`
    overflow-y: auto;
`;

const ExampleActions = styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;
    opacity: 0.2;
    > :not(:last-child) {
        margin-right: 8px;
    }
`;

const Example = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    padding: ${p => p.$sentiment ? '8px 8px 8px 18px' : '8px 8px 8px 24px'};
    border-left: ${p => p.$sentiment ? `6px solid ${p.theme[p.$sentiment]}` : 'none'};
    border-bottom: 1px solid ${p => p.theme.borderLight};
    font-size: 14px;
    > p {
        flex: 1;
        margin: 0;
    }
    &:hover ${ExampleActions} {
        opacity: 1;
    }
    ${p => p.$clickable && css`
        cursor: pointer;
        &:hover {
            background-color: ${p => p.theme.lavender};
        }
    `}
`;

const ExampleSentiment = styled.span`
    font-weight: ${p => p.theme.fontWeight.medium};
    text-transform: capitalize;
`;

const StyledHeading = styled(Heading).attrs({ size: 3 })`
    margin: 0;
    padding: 16px 24px;
    border-bottom: 1px solid ${p => p.theme.borderLight};
    font-size: 16px;
`;

const getCuratedExamples = (descriptor = {}) => (
    (descriptor.examples || []).map(example => example)
);

export const ExampleSelector = ({
    surveyId,
    reportGroupId,
    descriptor,
    examples,
    searchTextValue,
    curatedDescriptors = [],
}) => {
    const addCuratedExamples = useAddCuratedExamples();
    const updateCuratedExamples = useUpdateCuratedExamples();

    const curatedExamples = getCuratedExamples(curatedDescriptors[0]);

    const handleAddExampleClick = example => {
        if (curatedDescriptors.length) {
            updateCuratedExamples.mutateAsync({
                surveyId,
                id: curatedDescriptors[0].id,
                reportGroupId,
                descriptor,
                examples: [
                    ...curatedExamples,
                    example,
                ]
            });
        } else {
            addCuratedExamples.mutateAsync({
                surveyId,
                reportGroupId,
                descriptor,
                enabled: true,
                examples: [example]
            });
        }
    };

    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { backgroundColor: 'yellow' } : {} }>
                { part }
            </span>)
        } </span>;
    }
    return (
        <Wrapper>
            <Examples>
                <StyledHeading>User submitted examples</StyledHeading>
                {examples.map(({ example, sentiment }, index) => (
                    <Example
                        key={`${index}-${example}`}
                        $sentiment={sentiment}
                    >
                        <p><ExampleSentiment>{sentiment}</ExampleSentiment> - {getHighlightedText(example, searchTextValue)}</p>
                        {curatedExamples.indexOf(example) === -1 ? (
                            <ExampleActions>
                                <Button icon onClick={() => handleAddExampleClick(example)}>
                                    <Icon
                                        size="24px"
                                        type="exampleAdd"
                                    />
                                </Button>
                            </ExampleActions>
                        ) : null}
                    </Example>
                ))}
            </Examples>
            <CuratedExamples>
                <StyledHeading>Curated examples</StyledHeading>
                {curatedExamples.map((example, index) => (
                    <Example key={`${index}-${example}-curated`}>
                        <p>{getHighlightedText(example, searchTextValue)}</p>
                        <ExampleActions>
                            <EditCuratedExample
                                surveyId={surveyId}
                                reportGroupId={reportGroupId}
                                descriptor={descriptor}
                                curatedDescriptorId={curatedDescriptors[0].id}
                                examples={curatedExamples}
                                example={example}
                                searchTextValue={searchTextValue}
                            />
                            <DeleteCuratedExample
                                surveyId={surveyId}
                                reportGroupId={reportGroupId}
                                descriptor={descriptor}
                                curatedDescriptorId={curatedDescriptors[0].id}
                                examples={curatedExamples}
                                example={example}
                                searchTextValue={searchTextValue}
                            />
                        </ExampleActions>
                    </Example>
                ))}
            </CuratedExamples>
        </Wrapper>
    );
}