import React from 'react';

import { useUpdateCuratedExamples } from '~/queries';
import { Button, Modal, Overlay } from '~/ui';

export const DeleteCuratedExampleModal = ({
    surveyId,
    reportGroupId,
    descriptor,
    curatedDescriptorId,
    examples,
    example,
    searchTextValue,
    onClose,
}) => {
    const updateCuratedExamples = useUpdateCuratedExamples();

    const onSubmit = () => {
        updateCuratedExamples.mutateAsync({
            surveyId,
            id: curatedDescriptorId,
            reportGroupId,
            descriptor,
            examples: examples.filter(ex => ex !== example)
        }).then(onClose);
    };

    return (
        <Overlay dismissOnEscape>
            <Modal.Container>
                <Modal.Header onClose={onClose}>
                    Delete example
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the following example?</p>
                    <p><em>"{example}"</em></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onSubmit}>
                        Delete
                    </Button>
                    <Button secondary onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </Overlay>
    );
};
