import { useMsal, useAccount } from '@azure/msal-react';
import { useQuery } from 'react-query';

export const useToken = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    const { data, ...rest } = useQuery(
        'msalToken',
        () => instance.acquireTokenSilent({
            scopes: [
                `${process.env.AZURE_ENDPOINT}/user_impersonation`,
            ],
            account,
        }),
        {
            cacheTime: Infinity
        }
    );

    const { accessToken } = data || {};

    return {
        token: accessToken ? `Bearer ${accessToken}` : null,
        ...rest,
    }
};
