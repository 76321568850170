import React from 'react';
import styled from 'styled-components';

import { Checkbox, Select, TextField } from '~/ui';

const Wrapper = styled.div`
    flex: 1;
`;

export const FieldPreview = ({ field }) => (
    <Wrapper>
        {field.field_type === 'dropdown' ? (
            <Select
                disabled
                required={field.field_required}
                placeholder={`${field.field_label}${!field.field_required ? ' (optional)' : ''}`}
                options={[...field.field_options]}
            />
        ): null}
        {field.field_type === 'textField' ? (
            <TextField
                disabled
                required={field.field_required}
                placeholder={`${field.field_label}${!field.field_required ? ' (optional)' : ''}`}
            />
        ): null}
        {field.field_type === 'checkbox' ? (
            <Checkbox disabled label={field.field_label} />
        ): null}
    </Wrapper>
);
