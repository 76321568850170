import { useMutation, useQueryClient } from 'react-query';

import { api, ENDPOINTS, useToken } from '~/api';

export const useCreateCustomer = ({ surveyId }) => {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation(customer => api.post(
        `${ENDPOINTS.CUSTOMERS}`,
        customer,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            queryClient.invalidateQueries(`${ENDPOINTS.CUSTOMERS}/?surveyId=${surveyId}`);
        },
    });
};
