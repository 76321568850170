import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

import { useFields } from '~/contexts';
import { BOARD_TYPE } from '~/core';
import { Button, Icon } from '~/ui';

import ConfigurableField from './ConfigurableField';
import { DeleteFieldModal } from './DeleteFieldModal';
import { FieldPreview } from './FieldPreview';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    ${p => p.$isDragging && css`
        opacity: 0.5;
    `}
    ${({ $isEdit }) => $isEdit && css`
        padding: 16px 16px 0px 16px;
        border: 1px solid ${p => p.theme.border};
    `}
`;

const DragIcon = styled(Icon)`
    flex: none;
    display: none;
    margin-right: 8px;
    opacity: 0.5;
    cursor: grab;
    &:hover {
        opacity: 1;
    }
    ${({ $isEdit }) => !$isEdit && css`
        display: block;
    `}
`;

const FieldActions = styled.div`
    margin-left: 16px;
    > :not(:last-child) {
        margin-right: 8px;
    }
`;

export const BiodataFormField = ({
    field,
    index,
    editingFieldId,
    handleMoveField,
}) => {
    const ref = useRef(null);
    const { dispatch } = useFields();
    const [modalVisible, setModalVisible] = useState(false);
    const isEdit = field._id === editingFieldId;
    const [, drop] = useDrop({
        accept: BOARD_TYPE,

        hover(field, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = field.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            handleMoveField(dragIndex, hoverIndex);
            field.index = hoverIndex;
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: BOARD_TYPE,
        item: () => ({...field, index}),
        canDrag: () => !isEdit && !modalVisible,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    return (
        <Wrapper
            $isEdit={isEdit}
            $isDragging={isDragging}
            ref={ref}
        >
            <DragIcon
                $isEdit={isEdit}
                color={p => p.theme.textLight}
                type="dragItem"
            />
            {isEdit ? (
                <ConfigurableField
                    key={field._id}
                    field={field}
                    onDismiss={() => dispatch({
                        payload: {
                            editingFieldId: null,
                        }
                    })}
                    onDeleteField={() => setModalVisible(true)}
                />
            ) : (
                <>
                    <FieldPreview field={field} />
                    {!editingFieldId || isEdit ? (
                        <FieldActions>
                            <Button
                                icon
                                onClick={() => dispatch({
                                    payload: {
                                        editingFieldId: field._id,
                                    }
                                })}
                                disabled={editingFieldId && !isEdit}
                            >
                                <Icon
                                    size="24px"
                                    type="edit"
                                />
                            </Button>
                            <Button
                                icon
                                onClick={() => setModalVisible(true)}
                                disabled={editingFieldId && !isEdit}
                            >
                                <Icon
                                    size="24px"
                                    type="deleteItem"
                                />
                            </Button>
                        </FieldActions>
                    ): null}
                </>
            )}
            {modalVisible ? (
                <DeleteFieldModal
                    fieldId={field._id}
                    fieldLabel={field.field_label}
                    onClose={() => setModalVisible(false)}
                />
            ) : null}
        </Wrapper>

    );
}
