import { useMutation, useQueryClient } from 'react-query';
import queryString from 'query-string';

import { api, ENDPOINTS, useToken } from '~/api';

export const useUpdateReportGroups = ({ surveyId, reportGroupId }) => {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation(() => api.patch(
        `${ENDPOINTS.REPORT_GROUPS}/${surveyId}/?${queryString.stringify({ reportGroupId})}`,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            queryClient.invalidateQueries(`${ENDPOINTS.REPORT_GROUPS}/${surveyId}`);
        },
    });
};
