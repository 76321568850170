import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '~/components';
import { ROUTES } from '~/core';
import { Heading, Icon, Link } from '~/ui';

import { SideNav } from './SideNav';

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
    height: 80px;
    margin-right: 24px;
    padding: 0 24px;
    border-right: 1px solid ${p => p.theme.borderLight};
`;

const SurveyTitle = styled(Heading)`
    margin: 0;
    width: 280px;
`;

const Content = styled.div`
    display: flex;
    height: calc(100% - 80px);
`;

const Main = styled.main`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-y: ${p => p.$scrollable ? 'auto' : 'visible'};
`;

export const Layout = ({ scrollable, children }) => {
    const { surveyId } = useParams();


    return (
        <>
            <Header>
                <LogoContainer>
                    <Link unstyled to={ROUTES.SURVEYS}>
                        <Icon
                            noFill
                            type="culturePsychLogo"
                            size="auto"
                        />
                    </Link>
                </LogoContainer>
                <SurveyTitle size={2}>{surveyId}</SurveyTitle>
            </Header>
            <Content>
                <SideNav />
                <Main $scrollable={scrollable}>
                    {children}
                </Main>
            </Content>
        </>
    );
};
