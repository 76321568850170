export const API_URL = `${process.env.AZURE_ENDPOINT}/api`;

export const ENDPOINTS = {
    CURATED_DESCRIPTORS: '/curated-descriptors',
    DESCRIPTORS: '/descriptors',
    REPORT_GROUPS: '/report-groups',
    SURVEYS: '/surveys',
    SURVEY_FIELDS: '/surveyfields',
    CUSTOMERS: '/customers'
};
