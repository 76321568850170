import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid ${p => p.theme.borderLight};
`;

export const Header = ({ children }) => (
    <Wrapper>
        {children}
    </Wrapper>
);
