import { useState, useLayoutEffect } from 'react';
import * as d3 from 'd3';

import { capitalize, getTopSentiment } from '~/core';

const findColor = (value, data) => {
    const obj = data.find(({ descriptor }) => descriptor === value);
    if (!obj) return;

    const topSentiment = getTopSentiment(obj.sentimentCount)

    switch (topSentiment) {
    case 'positive':
        return '#41d76d';
    case 'neutral':
        return '#ffdd28';
    case 'negative':
        return '#e61a4b';
    default:
        return '#ffdd28';
    }
};

export const useBubbleChart = ({ descriptors, rootId }) => {
    const [selected, setSelected] = useState('');

    const setSelectedDescriptor = id => setSelected(
        descriptors.find(({ descriptor }) => descriptor === id)
    )

    const resetSelected = () => setSelected('');

    useLayoutEffect(() => {
        if (!descriptors) return;

        var size = Math.min(
            Math.min(window.innerWidth, window.innerHeight),
            800
        );

        var chart = d3
            .select(`#${rootId}`)
            .html('')
            .append('svg')
            .attr('width', size)
            .attr('height', size);

        var pack = d3
            .pack()
            .size([size, size])
            .padding(size * 0.01);

        // count the word frequency
        const counts = Object.assign(
            {},
            ...descriptors.map(({ descriptor, responseCount }) => ({
                [descriptor]: responseCount,
            }))
        );

        var keys = Object.keys(counts);

        // sort the keys from highest to lowest
        keys.sort(function (a, b) {
            return counts[b] - counts[a];
        });

        var root = d3.hierarchy({ children: keys }).sum(function (d) {
            return counts[d];
        });

        var node = chart
            .selectAll('.node')
            .data(pack(root).leaves())
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('cursor', 'pointer')
            .attr('transform', function (d) {
                return 'translate(' + d.x + ',' + d.y + ')';
            });

        node.append('circle')
            .attr('id', function ({ data }) {
                return data;
            })
            .attr('r', function (d) {
                return d.r;
            })
            .style('fill', function ({ data }) {
                return findColor(data, descriptors);
            })
            .on('click', e => setSelectedDescriptor(e.target.id));

        node.append('clipPath')
            .attr('id', function ({ data }) {
                return 'clip-' + data;
            })
            .append('use')
            .attr('xlink:href', function ({ data }) {
                return '#' + data;
            });

        node.append('text')
            .attr('clip-path', function ({ data }) {
                return 'url(#clip-' + data + ')';
            })
            .append('tspan')
            .attr('x', 0)
            .attr('y', function ({ r }) {
                return r / 8;
            })
            .attr('fill', function () {
                return 'white';
            })
            .attr('text-anchor', function () {
                return 'middle';
            })
            .attr('font-size', function ({ r }) {
                return r / 5;
            })
            .text(function ({ data }) {
                return capitalize(data);
            })
            .on('click', e => setSelectedDescriptor(e.target.id));
    }, [descriptors]);

    return { selected, resetSelected };
};
