import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from './core/constants';
import { LazyRender, Loading } from './ui';
import { useToken } from './api';

const Surveys = lazy(() => import('./views/Surveys'));
const ReportGroups = lazy(() => import('./views/ReportGroups'));
const SingleReportGroup = lazy(() => import('./views/SingleReportGroup'));
const SingleDescriptor = lazy(() => import('./views/SingleDescriptor'));
const Reports = lazy(() => import('./views/Reports'));
const SingleReport = lazy(() => import('./views/SingleReport'));
const NotFound = lazy(() => import('./views/NotFound'));
const BiodataForm = lazy(() => import('./views/BiodataForm'));
const Customers = lazy(() => import('./views/Customers'));
export const Router = () => {
    // Fetch & cache token before rendering anything that calls Azure
    const { isLoading } = useToken();

    return (
        <LazyRender loading={isLoading}>
            <Suspense fallback={<Loading fill />}>
                <Switch>
                    <Route
                        exact
                        path={ROUTES.SURVEYS}
                        component={Surveys}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORT_GROUPS}`}
                        component={ReportGroups}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORT_GROUPS}/:reportGroupId`}
                        component={SingleReportGroup}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORT_GROUPS}/:reportGroupId/:descriptor`}
                        component={SingleDescriptor}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORTS}`}
                        component={Reports}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORTS}/:reportGroupId`}
                        component={SingleReport}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.BIODATA}`}
                        component={BiodataForm}
                    />
                    <Route
                        exact
                        path={`${ROUTES.SURVEYS}/:surveyId${ROUTES.CUSTOMERS}`}
                        component={Customers}
                    />
                    <Redirect
                        exact
                        from={`${ROUTES.SURVEYS}/:surveyId`}
                        to={`${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORT_GROUPS}`}
                    />

                    <Redirect exact from="/" to={ROUTES.SURVEYS} />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </LazyRender>
    );
};
