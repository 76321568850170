import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

import { ErrorMessage } from './ErrorMessage';
import { Label } from './Label';
import { useTheme } from '../theme';

const Wrapper = styled.div``;

const getStyles = theme => ({
    control: (base, state) => ({
        ...base,
        width: '100%',
        height: '40px',
        padding: '0',
        outline: 'none',
        border: `0px solid ${theme.background}`,
        borderRadius: '4px',
        cursor: 'pointer',
        color: theme.text,
        backgroundColor: state.isFocused ? theme.active : theme.background,
        boxShadow: 'none',
        fontFamily: theme.fontFamily,
        fontWeight: theme.fontWeight.regular,
    }),
    dropdownIndicator: base => ({
        ...base,
        overflow: 'hidden',
        height: '40px',
        padding: '10px',
        color: theme.textLight,
        '&:hover': {
            color: theme.textLight,
        },
    }),
    indicatorsContainer: () => ({
        width: '40px',
        height: '40px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        backgroundColor: theme.pattensBlue,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 100,
    }),
    option: (base, state) => ({
        ...base,
        minHeight: '36px',
        color: state.isSelected ? theme.text : theme.textLight,
        backgroundColor: state.isFocused ? theme.background : theme.white,
        cursor: 'pointer',
        fontFamily: theme.fontFamily,
        fontWeight: theme.fontWeight.regular,
        '&:hover, &:active': {
            backgroundColor: theme.background,
        },
    }),
    placeholder: base => ({
        ...base,
        color: theme.textLight,
    }),
    singleValue: base => ({
        ...base,
        marginLeft: 0,
        marginRight: 0,
        color: theme.text,
    }),
    valueContainer: base => ({
        ...base,
        padding: '0 12px',
    }),
});

export const Select = React.forwardRef(({
    id,
    required,
    disabled,
    label,
    placeholder,
    options,
    onChange,
    error,
    ...props
}, ref) => {
    const theme = useTheme();

    return (
        <Wrapper>
            {label ? (
                <Label>{label}{required ? ' (required)' : ''}</Label>
            ) : null}
            <ReactSelect
                id={id}
                isDisabled={disabled}
                placeholder={placeholder ? (
                    `${placeholder}${required ? ' (required)' : ''}`
                ) : ''}
                options={options}
                onChange={onChange}
                styles={getStyles(theme)}
                isSearchable={false}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                ref={ref}
                {...props}
            />
            {error ? (
                <ErrorMessage>
                    {error.message}
                </ErrorMessage>
            ) : null}
        </Wrapper>
    );
});
