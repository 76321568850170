// Returns the sentiment with the highest response count, or neutral
// in the case of a tie between the two or three highest counts.
export const getTopSentiment = sentiments => {
    const counts = Object.values(sentiments);
    const hasTie = (new Set(counts)).size !== counts.length;
    const lowestCount = Math.min(...counts);
    const highestCount = Math.max(...counts);

    //Return navyBlue color when:
    // there is tie and it's between highestCount of sentiments[0](positive) and sentiments[1] (negative)
    if(hasTie && (counts.filter(v => v === highestCount).length === 2) && (sentiments['positive'] === sentiments['negative'])){
        return 'navyBlue';
    }

    // Return the highest count when:
    // - There are no ties, or
    // - The tie is between the two lowest counts
    if (!hasTie || counts.filter(v => v === lowestCount).length === 2) {
        return Object.keys(sentiments).find(
            key => sentiments[key] === Math.max(...counts),
        );
    }

    // Return neutral when:
    // - There is a three way tie, or
    // - The two tied counts are higher than the other count
    return 'neutral';
};
