import { api, ENDPOINTS } from '~/api';


export const useReportGroups = ({ surveyId }) => {
    const { data, ...rest } = api.useQueryGet({
        endpoint: `${ENDPOINTS.REPORT_GROUPS}/${surveyId}`,
        enabled: !!surveyId,
    });

    return {
        data: (data || []).sort((a, b) => a.name > b.name ? 1 : -1),
        ...rest
    };
};
