import { useRouteMatch } from 'react-router-dom';

import { ROUTES } from '~/core';

const navRoutes = [
    `${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORT_GROUPS}`,
    `${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORTS}`,
    `${ROUTES.SURVEYS}/:surveyId${ROUTES.BIODATA}`,
    `${ROUTES.SURVEYS}/:surveyId${ROUTES.CUSTOMERS}`,
]

export function matchNavRoute() {
    const routeMatches = Object.values(navRoutes).filter(
        route => useRouteMatch(route),
    );

    if (routeMatches.length > 1) {
        throw new Error('Multiple route matches detected.');
    }

    return routeMatches[0];
}
