export const age = {
    label: 'Age group',
    values: [
        {
            label: '16 - 20',
            value: '16to20',
        },
        {
            label: '21 - 25',
            value: '21to25',
        },
        {
            label: '26 - 30',
            value: '26to30',
        },
        {
            label: '31 - 35',
            value: '31to35',
        },
        {
            label: '36 - 40',
            value: '36to40',
        },
        {
            label: '41 - 45',
            value: '41to45',
        },
        {
            label: '46 - 50',
            value: '46to50',
        },
        {
            label: '51 - 60',
            value: '51to60',
        },
        {
            label: '60+',
            value: '60plus',
        }
    ]
};

export const area = {
    label: 'Area',
    values: [
        {
            label: 'QLD',
            value: 'qldStores'
        },
        {
            label: 'WA',
            value: 'waStores'
        },
        {
            label: 'NSW 1',
            value: 'nsw1'
        },
        {
            label: 'NSW 2',
            value: 'nsw2'
        },
        {
            label: 'NSW 3',
            value: 'nsw3'
        },
        {
            label: 'VIC 1',
            value: 'vic1'
        },
        {
            label: 'VIC 2',
            value: 'vic2'
        }
    ]
};

export const brand = {
    label: 'Brand',
    values: [
        {
            label: 'Samsung',
            value: 'samsung'
        },
        {
            label: 'Nike',
            value: 'nike'
        }
    ]
};

export const country = {
    label: 'Country',
    values: [
        {
            label: 'Australia',
            value: 'aus'
        },
        {
            label: 'New Zealand',
            value: 'nz'
        },
    ]
};

export const department = {
    label: 'Department',
    values: [
        {
            label: 'People Function (HR & L&D)',
            value: 'people'
        },
        {
            label: 'Finance & LP & IT',
            value: 'finance'
        },
        {
            label: 'Merchandise',
            value: 'merchandise'
        },
        {
            label: 'Marketing & Customer Service',
            value: 'marketingAndCS'
        }
    ]
};

export const employmentType = {
    label: 'Employment type',
    values: [
        {
            label: 'Casual',
            value: 'casual'
        },
        {
            label: 'Full-time or part-time',
            value: 'fullTimeOrPartTime'
        }
    ]
};

export const gender = {
    label: 'Gender',
    values: [
        {
            label: 'Female',
            value: 'female'
        },
        {
            label: 'Male',
            value: 'male'
        },
        {
            label: 'Other',
            value: 'other'
        }
    ]
};

export const leadership = {
    label: 'Leadership',
    values: [
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]
};

export const roleType = {
    label: 'Role type',
    values: [
        {
            label: 'Store Team Member (Sales Specialist/Logistics)',
            value: 'storeTeamMember',
        },
        {
            label: 'Store Manager (SM ONLY)',
            value: 'storeManager',
        },
        {
            label: 'Store Leadership (ASM/3IC/Leads)',
            value: 'storeLeadership',
        },
        {
            label: 'Area Manager (AUS & NZ)',
            value: 'areaManager',
        },
    ]
};

export const samsungType = {
    label: 'Studio or SES',
    values: [
        {
            label: 'Studio',
            value: 'studio'
        },
        {
            label: 'SES',
            value: 'ses'
        }
    ]
};

export const shiftsPerWeek = {
    label: 'Shfits per week',
    values: [
        {
            label: '2 or less per week',
            value: '2orLess'
        },
        {
            label: '3 or more per week',
            value: '3orMore'
        }
    ]
};

export const staffType = {
    label: 'Staff type',
    values: [
        {
            label: 'Stores',
            value: 'stores',
        },
        {
            label: 'Support Office',
            value: 'supportOffice',
        },
    ]
};

export const storeLeader = {
    label: 'Store leader',
    values: [
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]
};

export const tenure = {
    label: 'Tenure',
    values: [
        {
            label: 'Less than 6 months',
            value: 'lessThan6months'
        },
        {
            label: '6 months - 1 year',
            value: '6monthsTo1year'
        },
        {
            label: '1 - 2 years',
            value: '1to2years'
        },
        {
            label: '3 - 5 years',
            value: '3to5years'
        },
        {
            label: '6 - 8 years',
            value: '6to8years'
        },
        {
            label: '9+ years',
            value: '9plusYears'
        }
    ]
};

export const fieldType = {
    label: 'Field Type',
    values: [
        {
            label: 'Text field',
            value: 'textField'
        },
        {
            label: 'Dropdown',
            value: 'dropdown'
        },
        {
            label: 'Checkbox',
            value: 'checkbox'
        }
    ]
};

export const conditionType = {
    label: 'Condition Type',
    values: [
        {
            label: 'Equal to',
            value: 'equal'
        },
        {
            label: 'Not Equal to',
            value: 'notEqual'
        }
    ]
};

export const checkboxValue = {
    label: 'Checkbox Value',
    values: [
        {
            label: 'True',
            value: true
        },
        {
            label: 'False',
            value: false
        }
    ]
};

