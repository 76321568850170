import { api, ENDPOINTS } from '~/api';
import queryString from 'query-string';

export const useDescriptors = ({ reportGroupId, searchTextValue }) => {
    const { data, ...rest } = api.useQueryGet({
        endpoint: searchTextValue ? (
            `${ENDPOINTS.DESCRIPTORS}?${queryString.stringify({ reportGroupId, searchParam: searchTextValue })}`
        )
        : (
            `${ENDPOINTS.DESCRIPTORS}?${queryString.stringify({reportGroupId})}`
        )
    });

    return {
        data: data || [],
        ...rest
    };
};
