import React from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateCuratedExamples } from '~/queries';
import { Button, Form, Modal, Overlay, TextField } from '~/ui';

export const EditCuratedExampleModal = ({
    surveyId,
    reportGroupId,
    descriptor,
    curatedDescriptorId,
    examples,
    example,
    searchTextValue,
    onClose,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        shouldUnregister: true,
        defaultValues: {
            example: example,
        },
    });

    const updateCuratedExamples = useUpdateCuratedExamples();

    const onSubmit = formData => {
        const exampleIndex = examples.findIndex(ex => ex === example);
        const newExamples = [...examples];
        newExamples[exampleIndex] = formData.example;

        updateCuratedExamples.mutateAsync({
            surveyId,
            id: curatedDescriptorId,
            reportGroupId,
            descriptor,
            examples: newExamples
        }).then(onClose);
    };

    return (
        <Overlay dismissOnEscape>
            <Modal.Container>
                <Modal.Header onClose={onClose}>
                    Edit example
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <TextField
                            required
                            multiline
                            label="Example"
                            error={errors.example}
                            {...register('example', { required: 'Please enter some text for this example' })}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                    <Button secondary onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </Overlay>
    );
};
