import React from 'react';
import styled, { css } from 'styled-components';

const BaseButton = styled.button`
    height: 40px;
    min-height: 40px;
    border: none;
    border-radius: 4px;
    cursor: ${p => p.disabled ? 'default' : 'pointer'};
`;

const PrimaryButton = styled(BaseButton)`
    padding: 0 16px;
    color: ${p => p.theme.white};
    font-size: ${p => p.theme.fontSize.large};
    font-weight: ${p => p.theme.fontWeight.medium};
    ${p => !p.disabled && css`
        background: linear-gradient(
            180deg,
            ${p => p.theme.primaryLight} 0%,
            ${p => p.theme.primary} 100%
        );
    `};
    ${p => p.disabled && css`
        background-color: ${p => p.theme.pattensBlue};
    `}
`;

const SecondaryButton = styled(BaseButton)`
    padding: 0 16px;
    color: ${p => p.theme.text};
    font-size: ${p => p.theme.fontSize.large};
    font-weight: ${p => p.theme.fontWeight.medium};
    ${p => !p.disabled && css`
        border: 2px solid ${p.theme.borderLight};
        background: linear-gradient(
            180deg,
            ${p => p.theme.lavender} 0%,
            ${p => p.theme.aliceBlue} 100%
        );
    `};
    ${p => p.disabled && css`
        background-color: ${p => p.theme.pattensBlue};
    `}
`;

const IconButton = styled(BaseButton)`
    width: 40px;
    padding: 8px;
    background-color: transparent;
    color: ${p => p.theme.text};
    :hover {
        background-color: ${p => p.theme.lavender};
    }
`;

export const Button = ({
    icon,
    secondary,
    loading,
    disabled,
    onClick = () => {},
    children,
    ...props
}) => {
    const ButtonComponent = icon ? IconButton : secondary ? SecondaryButton : PrimaryButton;

    return (
        <ButtonComponent
            disabled={disabled}
            onClick={onClick}
            {...props}
        >
            {children}
        </ButtonComponent>
    );
};
