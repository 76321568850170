import { api, ENDPOINTS } from '~/api';

export const useSurveys = () => {
    const { data, ...rest } = api.useQueryGet({
        endpoint: ENDPOINTS.SURVEYS,
    });

    return {
        data: data || [],
        ...rest
    };
};
