import React from 'react';
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import { Button } from '~/ui';
import { useUpdateBiodataFields } from '~/queries';


export const BiodataSaveButton = () => {
    const { surveyId } = useParams();
    const updateBiodataFields = useUpdateBiodataFields({ surveyId });

    const handleSubmit = () => {
        const notifications = toast.loading('Loading....', {
            style: {
                background: 'white',
                color: 'green',
            }
        });
        updateBiodataFields.mutateAsync()
        .then(() => {
            toast('Successful!', {
                duration: 4000,
                style: {
                    background: 'green',
                    color: 'white',
                }
            })
          })
        .catch((err) => {
            console.log(err);
            toast('Something went wrong!', {
                style: {
                    background: 'red',
                    color: 'white',
                }
            })
          })
        .finally(() => {
            toast.dismiss(notifications);
        })
    };
    return (
        <>
            <Toaster position='bottom-center'/>
            <Button
                onClick={() => handleSubmit()}
            >
                Save
            </Button>
        </>

     );
}