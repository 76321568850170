import addItem from './add.svg';
import checkbox from './checkbox.svg';
import chevronLeft from './chevronLeft.svg';
import chevronRight from './chevronRight.svg';
import cross from './cross.svg';
import culturePulseLogo from './culturePulseLogo.svg';
import culturePsychLogo from './culturePsychLogo.svg';
import deleteItem from './deleteItem.svg';
import dragItem from './drag.svg';
import dropdown from './dropdown.svg';
import edit from './edit.svg';
import exampleAdd from './exampleAdd.svg';
import reportGroup from './reportGroup.svg';
import textField from './textField.svg';
import tick from './tick.svg';

export default new Map([
    ['addItem', addItem],
    ['checkbox', checkbox],
    ['chevronLeft', chevronLeft],
    ['chevronRight', chevronRight],
    ['cross', cross],
    ['culturePulseLogo', culturePulseLogo],
    ['culturePsychLogo', culturePsychLogo],
    ['deleteItem', deleteItem],
    ['dragItem', dragItem],
    ['dropdown', dropdown],
    ['edit', edit],
    ['exampleAdd', exampleAdd],
    ['reportGroup', reportGroup],
    ['textField', textField],
    ['tick', tick],
]);
