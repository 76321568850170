import React from 'react';
import styled, {
    ThemeContext as StyledThemeContext,
    ThemeProvider as StyledThemeProvider,
} from 'styled-components';

import variables from './variables';

const ThemeRoot = styled.div`
    width: 100%;
    height: 100%;
    color: ${p => p.theme.text};
    font-family: ${p => p.theme.fontFamily};
    font-weight: ${p => p.theme.fontWeight.regular};
    font-size: 16px;
`;

export const ThemeProvider = ({ children }) => (
    <StyledThemeProvider theme={variables}>
        <ThemeRoot>
            {children}
        </ThemeRoot>
    </StyledThemeProvider>
);

export const useTheme = () => React.useContext(StyledThemeContext);
