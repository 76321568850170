import { useMutation, useQueryClient } from 'react-query';

import { api, ENDPOINTS, useToken } from '~/api';
import { useFields } from '~/contexts';

export const useUpdateBiodataFields = ({ surveyId }) => {
    const queryClient = useQueryClient();

    const { token } = useToken();
    const { fields, dispatch } = useFields();

    const data = {
        surveyID: surveyId,
        fields: fields,
    };

    return useMutation(() => api.post(
        `${ENDPOINTS.SURVEY_FIELDS}/?surveyID=${surveyId}`,
        data,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            dispatch({
                payload: {
                    isFormIncomplete: false
                }
            });
        },
    });
};
