import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import { ITEM_TYPE } from '~/core';
import { Icon } from '~/ui';

const StyledField = styled.button`
    min-height: 40px;
    min-width: 150px;
    border-radius: 4px;
    cursor: ${p => p.disabled ? 'no-drop' : 'grab'};
    color: ${p => p.theme.primaryLight};
    font-weight: ${p => p.theme.fontWeight.medium};
    background-color: ${p => p.theme.white};
    border: 1px solid ${p => p.theme.borderLight};
`;

const StyledText = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;

const StyledIcon = styled(Icon)`
    margin-right: 8px;
`;

export const DraggableField = ({
    disabled,
    field_type,
    label,
    ...props
}) => {

    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: () => ({field_type}),
        collect: (monitor) => ({
            item: monitor.getItem(),
            isDragging: monitor.isDragging()
        })
    })

    drag(!disabled ? ref : null);

    return (
        <StyledField
            style={{ opacity: (isDragging || disabled) ? '0.5' : '1' }}
            disabled={disabled}
            {...props}
            ref={ref}
        >
            <StyledText>
                <StyledIcon
                    size="24px"
                    color={p => p.theme.primary}
                    type={field_type}
                />
                <span>
                    {label}
                </span>
            </StyledText>
        </StyledField>
    );
};
