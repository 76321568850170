import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { capitalize } from '~/core';
import { useCuratedDescriptors } from '~/queries';
import { Heading, LazyRender, Overlay } from '~/ui';

const Wrapper = styled.div`
    position: relative;
    pointer-events: all;
    z-index: 30;
    width: 600px;
    margin: 0 16px;
    padding: 24px;
    background-color: ${p => p.theme.white};
    border-radius: 16px;
    ul {
        margin-top: 24px;
        li {
            list-style: none;
            color: ${p => p.theme.textLight};
        }
    }
`;

export const DescriptorExamplesModal = ({
    descriptor,
    onClose,
}) => {
    const { reportGroupId } = useParams();
    const { data: curatedDescriptors, isLoading } = useCuratedDescriptors({ descriptor, reportGroupId });
    const curatedExamples = curatedDescriptors[0]?.examples || [];

    return (
        <Overlay onBlur={onClose} dismissOnEscape>
            <Wrapper>
                <Heading>{capitalize(descriptor)}</Heading>
                <LazyRender loading={isLoading}>
                    {curatedExamples.length ? (
                        <ul>
                            {curatedExamples.map(example => (
                                <li key={example}>
                                    <p>{example}</p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No examples available for this report group.</p>
                    )}
                </LazyRender>
            </Wrapper>
        </Overlay>
    );
};
