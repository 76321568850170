import React from 'react';

import { useCuratedDescriptors, useDescriptor } from '~/queries';
import { LazyRender } from '~/ui';

import { ExampleSelector } from './ExampleSelector';


export const SingleDescriptor = ({
    surveyId,
    reportGroupId,
    descriptor: encodedDescriptor,
    searchTextValue
}) => {
    const descriptor = decodeURIComponent(encodedDescriptor);

    const {
        data: descriptorData,
        isLoading: isLoadingDescriptor,
    } = useDescriptor({ descriptor, reportGroupId, searchTextValue });

    const {
        data: curatedDescriptors,
        isLoading: isLoadingExamples,
    } = useCuratedDescriptors({ descriptor, reportGroupId, searchTextValue });


    const examples = (descriptorData.examples || []).reduce(
        (acc, cur) => {
            const examplesWithSentiment = cur.examples.map(
                example => ({ example, sentiment: cur.sentiment })
            );
            acc.push(...examplesWithSentiment)
            return acc;
        }, []
    );

    return (
        <LazyRender loading={isLoadingDescriptor || isLoadingExamples}>
            <ExampleSelector
                surveyId={surveyId}
                reportGroupId={reportGroupId}
                descriptor={descriptor}
                examples={examples}
                curatedDescriptors={curatedDescriptors}
                searchTextValue={searchTextValue}
            />
        </LazyRender>
    );
}
