// https://www.color-blindness.com/color-name-hue/
const colors = {
    white: '#ffffff',
    lavender: '#f5f8fd',
    aliceBlue: '#f1f7ff',
    whiteSmoke: '#eeeeee',
    aliceBlueDark: '#ecf4ff',
    pattensBlue: '#d2deef',
    periwinkle: '#cdd7e5',
    spindle: '#c2cede',
    heather: '#acb5c3',
    baliHai: '#8e9bae',
    fiord: '#56606f',
    tuna: '#484e58',
    ebonyClay: '#32363d',
    black: '#000000',
    amaranth: '#e61a4b',
    frenchRose: '#f2547a',
    emerald: '#41d76d',
    gorse: '#ffdd28',
    pale: '#fad3dc',
    echoBlue: '#a2b0c4',
    scrollbarLight: '#888888',
    scrollbarDark: '#555555',
    navyBlue: '#003478'
};

const swatches = {
    primary: colors.amaranth,
    primaryLight: colors.frenchRose,
    positive: colors.emerald,
    neutral: colors.gorse,
    negative: colors.amaranth,
    background: colors.aliceBlue,
    border: colors.spindle,
    borderLight: colors.whiteSmoke,
    active: colors.pattensBlue,
    activeDark: colors.echoBlue,
    heading: colors.fiord,
    text: colors.ebonyClay,
    textLight: colors.fiord,
    draggable: colors.pale,
};

const typography = {
    fontFamily: "'Rubik', sans-serif",
    fontWeight: {
        regular: 400,
        medium: 500,
        bold: 600,
    },
    fontSize: {
        xsmall: '12px',
        small: '14px',
        medium: '16px',
        large: '18px',
        xlarge: '21px',
        xxlarge: '24px',
    },
};

export default {
    ...colors,
    ...swatches,
    ...typography,
};
