import React from 'react';
import styled from 'styled-components';

import { useTheme } from '../theme';
import { Icon } from './Icon';

const Input = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
`;

const IndicatorIcon = styled(Icon)``;

const Indicator = styled.div`
    flex: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 24px;
    background-color: ${p => p.theme.border};
    ${IndicatorIcon} {
        display: none;
    }
    ${Input}:checked ~ & {
        background-color: ${p => p.theme.primary};
        ${IndicatorIcon} {
            display: block;
        }
    }
`;

const Wrapper = styled.label`
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 6px 18px 6px 6px;
    border-radius: 24px;
    background-color: ${p => p.theme.background};
    cursor: pointer;
`;

const Label = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: ${p => p.theme.fontWeight.medium};
    color: ${p => p.$disabled ? p.theme.textLight : p.theme.text};
`;

export const Checkbox = React.forwardRef(({
    id,
    error,
    required,
    disabled,
    label,
    className,
    ...props
}, ref) => {
    const theme = useTheme();

    return (
        <Wrapper>
            <Input
                disabled={disabled}
                ref={ref}
                type="checkbox"
                {...props}
            />
            <Indicator>
                <IndicatorIcon
                    type="tick"
                    color={theme.white}
                    size="24px"
                />
            </Indicator>
            <Label title={label} $disabled={disabled}>
                {label}
            </Label>
        </Wrapper>
    );
});
