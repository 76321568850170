import { useMutation, useQueryClient } from 'react-query';

import { api, ENDPOINTS, useToken } from '~/api';

export const useCreateReportGroup = ({ surveyId }) => {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation(reportGroup => api.post(
        `${ENDPOINTS.REPORT_GROUPS}/${surveyId}`,
        reportGroup,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            queryClient.invalidateQueries(`${ENDPOINTS.REPORT_GROUPS}/${surveyId}`);
        },
    });
};
