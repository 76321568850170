import { api, ENDPOINTS } from '~/api';
import queryString from 'query-string';

export const useCuratedDescriptors = ({ descriptor, reportGroupId, searchTextValue='' }) => {
    const endpoint = descriptor ? (
        searchTextValue ? (
            `${ENDPOINTS.CURATED_DESCRIPTORS}/${escape(encodeURIComponent(descriptor))}?${queryString.stringify({ reportGroupId, searchParam: searchTextValue })}`
        ) : (
            `${ENDPOINTS.CURATED_DESCRIPTORS}/${escape(encodeURIComponent(descriptor))}?${queryString.stringify({reportGroupId})}`
        )
    ) : (
        searchTextValue ? (
            `${ENDPOINTS.CURATED_DESCRIPTORS}?${queryString.stringify({ reportGroupId, searchParam: searchTextValue })}`
        ) : (
            `${ENDPOINTS.CURATED_DESCRIPTORS}?${queryString.stringify({reportGroupId})}`
        )
    );

    const { data, ...rest } = api.useQueryGet({
        endpoint,
        queryKey: ['curatedDescriptor', endpoint]
    });

    return {
        data: data || [],
        ...rest
    };
};
