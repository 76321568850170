import { useMutation, useQueryClient } from 'react-query';
import queryString from 'query-string';

import { api, ENDPOINTS, useToken } from '~/api';

export const useUpdateDescriptors = ({ reportGroupId, descriptor, searchTextValue }) => {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation(() => api.post(
        `${ENDPOINTS.DESCRIPTORS}/${escape(encodeURIComponent(descriptor))}?${queryString.stringify({reportGroupId})}`,
        { headers: { Authorization: token } }
    ), {
        onSuccess: () => {
            queryClient.invalidateQueries(`${ENDPOINTS.DESCRIPTORS}?${queryString.stringify({reportGroupId})}`);
            queryClient.invalidateQueries(`${ENDPOINTS.CURATED_DESCRIPTORS}?${queryString.stringify({ reportGroupId})}`);
            searchTextValue ? (
                queryClient.invalidateQueries(`${ENDPOINTS.DESCRIPTORS}?${queryString.stringify({ reportGroupId, searchParam: searchTextValue })}`)
            ) : null;
        },
    });
};
