import React, { useState } from 'react';
import styled from 'styled-components';

import * as refData from '~/core/refData';
import { Button, Icon, Link } from '~/ui';
import { DeleteReportGroup } from './DeleteReportGroup';

const StyledLink = styled(Link)`
    display: block;
    overflow: hidden;
    width: 640px;
    padding: 12px 24px;
    border: 1px solid ${p => p.theme.borderLight};
    border-radius: 8px;
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    :hover {
        border: 1px solid ${p => p.theme.border};
        background-color: ${p => p.theme.lavender};
    }
`;

const GroupDetails = styled.div`
    display: flex;
    align-items: center;
`;

const Filters = styled.div`
    margin-top: 8px;
    color: ${p => p.theme.textLight};
    font-size: ${p => p.theme.fontSize.small};
`;

const StyledIcon = styled(Icon)`
    margin-right: 8px;
`;

const StyledButton = styled(Button)`
    margin: 0 0 8px 16px;
`;

export const ReportGroup = ({
    name,
    pathname,
    surveyId,
    reportGroupId,
    filters = [],
    fields = [],
    linkTo,
}) => {
    const [modalVisible, setModalVisible] = useState(false);

    const labelledFilters = filters.map(
        ({ key, value }) => {
            const selectedField = fields.find(
                ({ field_id }) => field_id === key
            );

            const filterLabel = selectedField?.field_label ?? key;

            return `${filterLabel}: ${value}`;
        }
    ).join(', ');

    return (
        <>
            <StyledLink unstyled to={linkTo}>
                <GroupDetails>
                    <StyledIcon size="24px" type="reportGroup" />
                    <span>{name}</span>
                </GroupDetails>
                <Filters>
                    {labelledFilters || 'All respondents'}
                </Filters>
            </StyledLink>
            {pathname === 'report-group' ? (
                <>
                    <StyledButton icon onClick={() => setModalVisible(true)}>
                        <Icon
                            size="24px"
                            type="deleteItem"
                        />
                    </StyledButton>
                    {modalVisible ? (
                        <DeleteReportGroup
                            surveyId={surveyId}
                            reportGroupId={reportGroupId}
                            reportGroupName={name}
                            onClose={() => setModalVisible(false)}
                        />
                    ) : null}
                </>
            ) : null}
        </>
    )
};
