import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';

import App from './App';

const msalConfig = {
    auth: {
        clientId: process.env.MSAL_CLIENT_ID,
        authority: process.env.MSAL_AUTHORITY,
        redirectUri: process.env.TF_VAR_CP_APP_URL,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <App msalInstance={pca} />,
    document.getElementById('root')
);
