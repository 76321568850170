import React, { useState } from 'react';

import { Button, Icon } from '~/ui';
import { DeleteCuratedExampleModal } from './DeleteCuratedExampleModal';

export const DeleteCuratedExample = ({
    surveyId,
    reportGroupId,
    descriptor,
    curatedDescriptorId,
    examples,
    example,
    searchTextValue
}) => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <div>
            <Button icon onClick={() => setModalVisible(true)}>
                <Icon
                    size="24px"
                    type="deleteItem"
                />
            </Button>
            {modalVisible ? (
                <DeleteCuratedExampleModal
                    surveyId={surveyId}
                    reportGroupId={reportGroupId}
                    descriptor={descriptor}
                    curatedDescriptorId={curatedDescriptorId}
                    examples={examples}
                    example={example}
                    searchTextValue={searchTextValue}
                    onClose={() => setModalVisible(false)}
                />
            ) : null}
        </div>
    )
}