import { api, ENDPOINTS } from '~/api';


export const useCustomers = ({ surveyId }) => {
    const { data, ...rest } = api.useQueryGet({
        endpoint: `${ENDPOINTS.CUSTOMERS}/?surveyId=${surveyId}`,
    });

    return {
        data: (data || []).sort((a, b) => a.username > b.username ? 1 : -1),
        ...rest
    };
};
