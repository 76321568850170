import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { matchNavRoute, ROUTES } from '~/core';
import { Link } from '~/ui';

const Wrapper = styled.nav`
    width: 240px;
    border-right: 1px solid ${p => p.theme.borderLight};
`;

const NavList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 16px 0;
`;

const NavItem = styled.li`
    padding: 12px 24px;
    color: ${p => p.$active ? p.theme.primary : p.theme.text};
    background-color: ${p => p.$active ? p.theme.background : p.theme.white};
    :hover {
        background-color: ${p => p.theme.background};
    }
`;

const NavItemLabel = styled.span`
    font-weight: ${p => p.theme.fontWeight.medium};
`;

export const SideNav = () => {
    const route = matchNavRoute();
    const { surveyId } = useParams();

    return (
        <Wrapper>
            <NavList>
                <Link unstyled to={`${ROUTES.SURVEYS}/${surveyId}${ROUTES.REPORT_GROUPS}`}>
                    <NavItem $active={route === `${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORT_GROUPS}`}>
                        <NavItemLabel>
                            Report groups
                        </NavItemLabel>
                    </NavItem>
                </Link>
                <Link unstyled to={`${ROUTES.SURVEYS}/${surveyId}${ROUTES.REPORTS}`}>
                    <NavItem $active={route === `${ROUTES.SURVEYS}/:surveyId${ROUTES.REPORTS}`}>
                        <NavItemLabel>
                            Reports
                        </NavItemLabel>
                    </NavItem>
                </Link>
                <Link unstyled to={`${ROUTES.SURVEYS}/${surveyId}${ROUTES.BIODATA}`}>
                    <NavItem $active={route === `${ROUTES.SURVEYS}/:surveyId${ROUTES.BIODATA}`}>
                        <NavItemLabel>
                            Biodata
                        </NavItemLabel>
                    </NavItem>
                </Link>
                <Link unstyled to={`${ROUTES.SURVEYS}/${surveyId}${ROUTES.CUSTOMERS}`}>
                    <NavItem $active={route === `${ROUTES.SURVEYS}/:surveyId${ROUTES.CUSTOMERS}`}>
                        <NavItemLabel>
                            User Access
                        </NavItemLabel>
                    </NavItem>
                </Link>
            </NavList>
        </Wrapper>
    );
};