import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import shortid from 'shortid';

import { useToken } from '~/api';

const FieldsContext = React.createContext();

const defaultFieldProps = {
    _id: null,
    field_id: '',
    field_type: '',
    field_label: '',
    field_value: '',
    field_required: true,
    field_options: [],
    field_conditions: [],
}

function fieldReducer(state, action) {
    const { payload, type } = action;

    const getMissingOptions = (currentFieldOptions, payloadFieldOptions) => {
        const  filteredOptions = currentFieldOptions.filter(option => !payloadFieldOptions.some((payloadOption) => option === payloadOption));

        return{
            field_id: payload.field_id,
            value: filteredOptions
        }
    }
    // if(type === 'INITIALIZE_FIELD'){
    //     return {
    //         fields: [
    //             ...state.fields,
    //             ...payload
    //         ]
    //     };
    // }

    if(type === 'INITIALIZE_FIELD'){
        return {
            fields: [
                ...payload
            ],
            isFormIncomplete: false,
        };
    }

    if (type === 'ADD_FIELD') {
        const newField = {
            ...defaultFieldProps,
            field_type: payload.field_type,
            _id: shortid.generate()
        }

        return {
            fields: [
                ...state.fields,
                newField,
            ],
            editingFieldId: newField._id,
            isFormIncomplete: true
        };
    }

    if (type === 'UPDATE_FIELD') {
        let existingFields = [...state.fields];
        const targetField = existingFields.find(field => field._id === payload._id);

        const missingOptions = getMissingOptions(targetField.field_options, payload.field_options);

        if(missingOptions.value.length){
            existingFields = existingFields.map(
                (field) => ({
                    ...field,
                    field_conditions: field.field_conditions.filter(
                        (condition) => (condition.field_id === missingOptions.field_id && missingOptions.value.includes(condition.value))
                            ? condition.field_id !== missingOptions.field_id
                            : condition
                    )
                })
            );
        }

        if (payload.field_id === targetField.field_id) {
            return {
                fields: existingFields.map(
                    field => field._id === payload._id
                        ? { ...field, ...payload }
                        : field
                ),
                isFormIncomplete: true,
            };
        }

        const updatedFields = existingFields.map(
            (field) => ({
                ...field,
                field_conditions: field.field_conditions.map(
                    (condition) => condition.field_id === targetField.field_id
                        ? {
                            ...condition,
                            field_id: payload.field_id,
                        }
                        : condition
                )
            })
        );

        return {
            fields: updatedFields.map(
                field => field._id === payload._id
                    ? { ...field, ...payload }
                    : field
            ),
            isFormIncomplete: true
        };
    }

    if (type === 'MOVE_FIELD') {
        const { dragIndex, hoverIndex } = payload;

        const draggedField = state.fields[dragIndex];
        const fields = [...state.fields];
        fields.splice(dragIndex, 1);
        fields.splice(hoverIndex, 0, draggedField);

        return { fields, isFormIncomplete: true };
    }

    if (type === 'DELETE_FIELD') {
        return {
            editingFieldId: null,
            fields: state.fields.filter(
                field => field._id !== payload._id
            ),
            isFormIncomplete: true,
        }
    }
    return { ...state, ...payload };
}

const initialState = {
    editingFieldId: null,
    fields: [],
    isFormIncomplete: false,
};

export const FieldsProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(fieldReducer, initialState);
    const { surveyId } = useParams();
    const { token } = useToken();

    useEffect(() => {
        fetch(`${process.env.AZURE_ENDPOINT}/api/surveyfields/?surveyID=${surveyId}`,
            { headers: { Authorization: token } }
        ).then(res => res.json())
        .then(data => {
            dispatch({
                type: 'INITIALIZE_FIELD',
                payload: data?.fields
            })
        }).catch(() => {
            dispatch({
                type: 'INITIALIZE_FIELD',
                payload: []
            })
        })
    }, [surveyId]);
    
    return (
            <FieldsContext.Provider
                value={{
                    ...state,
                    dispatch,
                }}
            >
                {children}
            </FieldsContext.Provider>
    );
};

export const useFields = () => React.useContext(FieldsContext);
