import { api, ENDPOINTS } from '~/api';
import queryString from 'query-string';

export const useDescriptor = ({ descriptor, reportGroupId, searchTextValue='' }) => {
    const { data, ...rest } = api.useQueryGet({
        endpoint: searchTextValue ? (
            `${ENDPOINTS.DESCRIPTORS}/${escape(encodeURIComponent(descriptor))}?${queryString.stringify({ reportGroupId, searchParam: searchTextValue })}`
            )
        : (
            `${ENDPOINTS.DESCRIPTORS}/${escape(encodeURIComponent(descriptor))}?${queryString.stringify({reportGroupId})}`
        )
    });

    return {
        data: data ? data[0] : {},
        ...rest
    };
};
