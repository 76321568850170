import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import "regenerator-runtime/runtime";
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { Loading, ThemeProvider } from '~/ui';

import { Router } from './Router';

import './app.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 60 * 10, // 10 minutes
        },
    },
});

const App = ({ msalInstance }) => (
    <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={() => <Loading fill />}
            authRequest={{
                scopes: [`${process.env.AZURE_ENDPOINT}/user_impersonation`]
            }}
        >
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ThemeProvider>
                        <Router />
                    </ThemeProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </MsalAuthenticationTemplate>
    </MsalProvider>
);

export default App;
