import React from "react";
import styled from "styled-components";


const CheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;
const CheckBoxLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 26px;
    border-radius: 15px;
    background: #bebebe;
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin: 3px;
        background: #ffffff;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`;
const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    margin: 0 8px 0 0;
    border-radius: 15px;
    width: 42px;
    height: 26px;
    cursor: pointer;
    &:checked + ${CheckBoxLabel} {
        background: ${p => p.theme.primary};
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
        }
    }
`;

const Label = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: ${p => p.theme.fontWeight.medium};
    color: ${p => p.$disabled ? p.theme.textLight : p.theme.text};
`;

export const ToggleSwitch = React.forwardRef(({
    id,
    error,
    required,
    disabled,
    label,
    className,
    ...props
}, ref) => {
    return (
        <CheckBoxWrapper>
            <CheckBox
                ref={ref}
                id="checkbox"
                type="checkbox"
                {...props}
            />
            <CheckBoxLabel htmlFor="checkbox" />
            <Label title={label} $disabled={disabled}>
                {label}
            </Label>
        </CheckBoxWrapper>
    );
});