import React from 'react';
import { useFields } from '~/contexts';

import { Button, Modal, Overlay } from '~/ui';

export const DeleteFieldModal = ({
    fieldId,
    fieldLabel,
    onClose,
}) => {
    const { dispatch } = useFields();

    const handleDeleteField = () => {
        dispatch({
            type: 'DELETE_FIELD',
            payload: {
                _id: fieldId,
            }
        });
    };

    return (
        <Overlay dismissOnEscape>
            <Modal.Container>
                <Modal.Header onClose={onClose}>
                    Delete field
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {fieldLabel ? (<>the <strong>{fieldLabel}</strong></>) : 'this'} field?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDeleteField}>
                        Delete field
                    </Button>
                    <Button secondary onClick={onClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </Overlay>
    );
};
